import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { DragDropContext, Draggable, DragStart, Droppable, DropResult } from 'react-beautiful-dnd'

import { Assets } from '@nexds/web'
import { useTheme } from 'styled-components'

import { UniqueEntityID } from '@/domain/core'
import { Route, Waypoint } from '@/domain/models'

import { useI18n } from '@/presentation/hooks/useI18n'
import { BackdropImperativeInterface } from '@/presentation/interfaces/BackdropImperativeInterface'
import { ContextMenu, ContextMenuItem } from '@/presentation/modules/Base/components/ContextMenu'
import { distanceNMFloat, flightTime } from '@/presentation/utils/route'
import { appZIndex } from '@/presentation/utils/zIndexMapper'
import { magneticBearing } from '@/utils/coordinates'

import { GpsExportOption, SimulatorExportOption } from '../../containers/RoutesPanelScreenPresenter'
import { Velocimeter } from '../Velocimeter/Velocimeter'
import { WaypointItem } from '../WaypointItem'
import {
  ActionButton,
  Actions,
  Clickable,
  Container,
  EmptyRoute,
  FakeSearchBox,
  Message,
  Header,
  TitleContainer,
  RouteNameInput,
  Title,
  WaypointsList,
  ScrollableContainer,
  Overlay
} from './RoutesPanelScreen.styles'

interface RoutesPanelScreenProps {
  route: Route
  isRouteSaved: boolean
  onDeleteWaypointFromRoute: (waypoint: Waypoint) => void
  onEditWaypointName: (waypoint: Waypoint, name: string) => void
  onEditRouteName: (newName: string) => void
  onClearRoute: () => void
  onSaveRoute: () => void
  onInvertRoute: () => void
  onSplitRoute: () => void
  onPrintRoute: () => void
  onSaveAsPdf: () => void
  onExportToGps: (option: GpsExportOption) => void
  OnExportToSimulator: (option: SimulatorExportOption) => void
  onCopyToFPLBR: () => void
  onDragEnd: (result: DropResult) => void
  onHighlightWaypoint: (waypointId: UniqueEntityID) => void
  onFadeWaypoint: () => void
}

function RoutesPanelScreen(props: RoutesPanelScreenProps) {
  const {
    route,
    isRouteSaved,
    onDeleteWaypointFromRoute,
    onEditWaypointName,
    onEditRouteName,
    onClearRoute,
    onSaveRoute,
    onInvertRoute,
    onSplitRoute,
    onSaveAsPdf,
    onPrintRoute,
    onExportToGps,
    OnExportToSimulator,
    onCopyToFPLBR,
    onDragEnd,
    onHighlightWaypoint,
    onFadeWaypoint
  } = props

  const theme = useTheme()
  const { t } = useI18n()

  const moreActions: ContextMenuItem[] = [
    {
      id: 'export-route',
      label: t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_MORE-ACTIONS_MAIN_EXPORT-ROUTE'),
      subItems: [
        {
          id: 'save-as-pdf',
          label: t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_MORE-ACTIONS_MAIN_SAVE-AS-PDF'),
          onItemClick: onSaveAsPdf
        },
        {
          id: 'print',
          label: t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_MORE-ACTIONS_MAIN_PRINT'),
          onItemClick: onPrintRoute
        }
      ]
    },
    {
      id: 'export-to-gps',
      label: t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_MORE-ACTIONS_MAIN_EXPORT-TO-GPS'),
      subItems: [
        {
          id: 'garmin',
          label: 'Garmin (.gpx)',
          onItemClick: onExportToGps,
          itemClickParam: 'garmin'
        }
      ]
    },
    {
      id: 'export-to-simulator',
      label: t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_MORE-ACTIONS_MAIN_EXPORT-TO-SIMULATOR'),
      subItems: [
        {
          id: 'MS FS2020',
          label: 'MS FS2020 (.pln)',
          onItemClick: OnExportToSimulator,
          itemClickParam: 'MS FS2020'
        },
        {
          id: 'XPlane 11',
          label: 'XPlane 11 (.fms)',
          onItemClick: OnExportToSimulator,
          itemClickParam: 'XPlane 11'
        },
        {
          id: 'XPlane 10',
          label: 'XPlane 10 (.fms)',
          onItemClick: OnExportToSimulator,
          itemClickParam: 'XPlane 10'
        }
      ]
    },
    {
      id: 'copy-to-fplbr',
      label: t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_MORE-ACTIONS_MAIN_COPY-TO-FPL-BR'),
      onItemClick: onCopyToFPLBR
    }
  ]

  const titleInputRef = useRef<HTMLInputElement>(null)
  const confirmButtonRef = useRef<HTMLButtonElement>(null)
  const contextMenuRef = useRef<HTMLDivElement>(null)

  const [routeNameInputValue, setRouteNameInputValue] = useState('')
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isMoreActionsOpen, setIsMoreActionsOpen] = useState(false)

  const distances = useMemo(() => {
    if (!route || !route.waypoints) return

    const distances = []
    let distanceAccumulated = 0
    let timeAccumulated = 0

    for (let i = 1; i <= route.waypoints.length - 1; i++) {
      const A = route.waypoints[i - 1].coordinates
      const B = route.waypoints[i].coordinates

      const distance = distanceNMFloat(A, B)
      let time = null

      if (route.groundSpeed) {
        time = flightTime(distance, route.groundSpeed)
      }

      distanceAccumulated += distance
      if (time) {
        timeAccumulated += time
      }

      distances.push({
        distanceAccumulated,
        timeAccumulated: timeAccumulated > 0 ? timeAccumulated : null,
        distance,
        bearing: magneticBearing(A, B),
        time
      })
    }

    return distances
  }, [route?.waypoints, route])

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (contextMenuRef.current && !contextMenuRef.current.contains(event.target as Node) && isMoreActionsOpen) {
        setIsMoreActionsOpen(false)
      }
      if (
        !titleInputRef.current?.contains(event.target as Node) &&
        !confirmButtonRef.current?.contains(event.target as Node) &&
        isInputFocused
      ) {
        if (route?.name) undoEditName()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isMoreActionsOpen, isInputFocused])

  useEffect(() => {
    if (!route) return

    setRouteNameInputValue(route.name)
  }, [route])

  useEffect(() => {
    if (isInputFocused) {
      titleInputRef.current?.select()
    }
  }, [isInputFocused])

  const handleEditTitle = useCallback(() => {
    if (route?.name !== routeNameInputValue) onEditRouteName(routeNameInputValue)
    setIsInputFocused(false)
  }, [routeNameInputValue, route?.name])

  const undoEditName = useCallback(() => {
    setRouteNameInputValue(route.name)
    setIsInputFocused(false)
  }, [route?.name])

  useEffect(() => {
    if (isMoreActionsOpen) {
      BackdropImperativeInterface.show(appZIndex.DRAWER, 0, () => setIsMoreActionsOpen(false))
    } else {
      BackdropImperativeInterface.hide()
    }
  }, [isMoreActionsOpen])

  return (
    <Container>
      <FakeSearchBox />
      {isMoreActionsOpen && <Overlay onClick={() => setIsMoreActionsOpen(false)} />}
      {route ? (
        <Header>
          {isInputFocused ? (
            <TitleContainer>
              <RouteNameInput
                ref={titleInputRef}
                value={routeNameInputValue}
                onKeyDown={(e) => {
                  if (e.key === 'Escape') undoEditName()
                  if (e.key === 'Enter') handleEditTitle()
                }}
                onChange={(e) => setRouteNameInputValue(e.target.value)}
              />
              <Clickable ref={confirmButtonRef} onClick={handleEditTitle}>
                <Assets.Check color={theme.colors.neutralL5} size={'sm'} />
              </Clickable>
            </TitleContainer>
          ) : (
            <TitleContainer>
              <Title hasRoute onClick={() => setIsInputFocused(true)}>
                {routeNameInputValue}
              </Title>
              <Clickable onClick={() => setIsInputFocused(true)}>
                <Assets.Edit color={theme.colors.neutralL5} size={'sm'} />
              </Clickable>
            </TitleContainer>
          )}
          <Velocimeter groundSpeed={route.groundSpeed} />
        </Header>
      ) : (
        <Header>
          <Title>{t('DRAWER_ROUTE-PANEL_MAIN_NO-ROUTE_ROUTE-NAME')}</Title>
        </Header>
      )}
      <Actions>
        <ActionButton
          disabled={!route}
          label={t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_NEW-ROUTE_LABEL')}
          onClick={onClearRoute}
        >
          <Assets.Rewrite color={theme.colors.neutralL5} size={28} />
        </ActionButton>
        <ActionButton
          disabled={!route || isRouteSaved}
          label={
            isRouteSaved
              ? t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_ROUTE-SAVED_LABEL')
              : t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_SAVE-ROUTE_LABEL')
          }
          onClick={onSaveRoute}
        >
          {isRouteSaved ? (
            <Assets.Saved color={theme.colors.neutralL5} size={28} />
          ) : (
            <Assets.Save color={theme.colors.neutralL5} size={28} />
          )}
        </ActionButton>
        <ActionButton
          disabled={!route}
          label={t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_INVERT-ROUTE_LABEL')}
          onClick={onInvertRoute}
        >
          <Assets.Sort color={theme.colors.neutralL5} size={28} />
        </ActionButton>
        <ActionButton
          disabled={!route}
          label={t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_SPLIT-ROUTE_LABEL')}
          onClick={onSplitRoute}
        >
          <Assets.ThirtyMinInterval color={theme.colors.neutralL5} size={28} />
        </ActionButton>
        <ActionButton
          disabled={!route}
          shouldHideTooltip={isMoreActionsOpen}
          label={t('DRAWER_ROUTE-PANEL_MAIN_ACTION-BUTTONS_MORE-ACTIONS_LABEL')}
          onClick={() => setIsMoreActionsOpen(true)}
        >
          <Assets.TripleDot color={theme.colors.neutralL5} size={28} />
          {isMoreActionsOpen && (
            <ContextMenu contextMenuRef={contextMenuRef} positionX={25} positionY={50} options={moreActions} />
          )}
        </ActionButton>
      </Actions>
      {route?.waypoints ? (
        <ScrollableContainer>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="waypoints-list">
              {(provided) => (
                <WaypointsList ref={provided.innerRef} {...provided.droppableProps}>
                  {route.waypoints.map((waypoint, index) => (
                    <WaypointItem
                      key={waypoint.id.toString()}
                      index={index}
                      waypoint={waypoint}
                      distanceInfo={index > 0 ? distances[index - 1] : null}
                      onEditName={onEditWaypointName}
                      onDelete={() => onDeleteWaypointFromRoute(waypoint)}
                      onMouseEnter={onHighlightWaypoint}
                      onMouseLeave={onFadeWaypoint}
                    />
                  ))}
                  {provided.placeholder}
                </WaypointsList>
              )}
            </Droppable>
          </DragDropContext>
        </ScrollableContainer>
      ) : (
        <EmptyRoute>
          <Assets.Windsock color={theme.colors.secondaryL3} size={40} />
          <Message>{t('DRAWER_ROUTE-PANEL_MAIN_NO-ROUTE_MESSAGE')}</Message>
        </EmptyRoute>
      )}
    </Container>
  )
}

export { RoutesPanelScreen }
