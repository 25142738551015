import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

export const FakeSearchBox = styled.div`
  height: 40px;
  margin-top: 8px;
  margin-bottom: 8px;

  @media (max-width: 430px) {
    width: 100%;
    margin-top: 18px;
  }
`

export const Overlay = styled.div`
  background-color: ${({ theme }) => `${theme.colors.secondaryD1}90`};
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9;
  inset: 0;

  border-top-right-radius: 20px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`

export const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 0;
  height: 48px;
  width: calc(360px - 12px - 12px);

  @media (max-width: 430px) {
    width: 100%;
  }
`

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  max-width: calc(360px - 12px - 12px - 84px); // 84px é a largura do VelocimeterContainer + paddings
  width: 100%;
  padding-right: 8px;

  & > button {
    visibility: hidden;
  }

  &:hover > button,
  & > button:hover {
    visibility: visible;
  }
`
export const RouteNameInput = styled.input`
  ${({ theme }) => theme.fonts.infoLG}
  width: 100%;
  border: none;
  height: 22px;
  border-radius: 4px;
  padding: 3px 6px 3px 0;
  background-color: transparent;
  flex: 1;

  &:focus {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`

interface TitleProps {
  hasRoute?: boolean
}

export const Title = styled.div<TitleProps>`
  text-transform: uppercase;
  text-align: center;
  ${({ theme }) => theme.fonts.infoLG}
  text-align: center;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  & + button {
    visibility: hidden;
  }

  &:hover + button,
  & + button:hover {
    visibility: visible;
  }
`

export const Actions = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 12px;
  justify-content: space-between;

  &::-webkit-scrollbar {
    display: none;
  }
`

interface ActionButtonProps {
  label: string
  shouldHideTooltip?: boolean
}

export const ActionButton = styled.button<ActionButtonProps>`
  border: none;
  background-color: transparent;
  width: 56px;
  height: 56px;
  cursor: pointer;
  position: relative;

  @media (max-width: 300px) {
    & > svg {
      width: 20px;
      height: 20px;
    }
  }

  &:disabled svg {
    opacity: 0.5;
  }

  &:disabled {
    cursor: default;
  }

  &::after {
    content: attr(label);
    display: ${({ shouldHideTooltip }) => (shouldHideTooltip ? 'none' : 'block')};
    position: absolute;

    top: calc(100% + 6px);
    left: 50%;
    transform: translate(-50%, -50%);

    height: fit-content;
    width: fit-content;
    border-radius: 4px;
    z-index: ${appZIndex.TOOLTIP};

    box-shadow: 1px 1px 1px ${({ theme }) => theme.colors.secondary};

    padding: 8px 12px;
    background-color: ${({ theme }) => theme.colors.secondaryL1};
    white-space: nowrap;
    ${({ theme }) => theme.fonts.infoSM}
    opacity: 0;
    visibility: hidden;
  }

  &:hover:after {
    opacity: 1;
    visibility: visible;
    transition: opacity 0.2s ease;
  }
`

export const Clickable = styled.button`
  border: none;
  background-color: transparent;
  line-height: 0;
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`

export const EmptyRoute = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset: 0;
  z-index: -1;
  gap: 12px;

  @media (max-height: 430px) {
    position: relative;
  }
`
export const Message = styled.p`
  ${({ theme }) => theme.fonts.infoMD}
  color: ${({ theme }) => theme.colors.secondaryL3};
  text-align: center;
  margin: 0 60px;
`

export const ScrollableContainer = styled.div`
  width: calc(100% + 9px);
  padding-right: 9px;
  flex: 1;

  overflow: auto;

  &::-webkit-scrollbar {
    border-radius: 20px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.secondaryL2};
    border-radius: 20px;
  }
`

export const WaypointsList = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
`
