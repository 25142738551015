import { useCallback, useState } from 'react'

import { MenuGroup, MenuItem, Radio } from '@nexds/web'

import { InjectionTokens } from '@/controller/tokens'

import { RouteWaypointInsertionMode } from '@/domain/protocols/RouteWaypointInsertionMode'
import { SettingsState, SettingsStateProps } from '@/domain/states/SettingsState'

import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'

import {
  BannerBox,
  BannerText,
  RouteWaypointInsertionModeContainer,
  Title
} from './RouteWaypointInsertionModeScreen.styles'

function RouteWaypointInsertionModeScreen() {
  const { t } = useI18n()
  const [settingsState, settingsStateMutator] = useGlobalState<SettingsState, SettingsStateProps>(
    InjectionTokens.SettingsState
  )

  const INITIAL_INSERTION_MODE = settingsState.waypointInsertionMode ?? 'smart'
  const [insertionMode, setInsertionMode] = useState(INITIAL_INSERTION_MODE)

  const handleInsertionModeChange = useCallback(
    (mode: RouteWaypointInsertionMode) => {
      settingsStateMutator.setWaypointInsertionMode(mode)
      setInsertionMode(mode)
    },
    [settingsStateMutator]
  )

  return (
    <RouteWaypointInsertionModeContainer>
      <Title>{t('DRAWER_SETTINGS_ROUTE-WAYPOINT-INSERTION-MODE-TITLE')}</Title>

      <MenuGroup>
        <MenuItem
          title={t('DRAWER_SETTINGS_ROUTE-WAYPOINT-INSERTION-MODE-DYNAMIC-TITLE')}
          size="sm"
          rightDecorator={<Radio value="smart" size="sm" checked={insertionMode === 'smart'} />}
          onClick={() => {
            handleInsertionModeChange('smart')
          }}
        />

        <MenuItem
          title={t('DRAWER_SETTINGS_ROUTE-WAYPOINT-INSERTION-MODE-MANUAL-TITLE')}
          size="sm"
          rightDecorator={<Radio value="manual" size="sm" checked={insertionMode === 'manual'} />}
          onClick={() => {
            handleInsertionModeChange('manual')
          }}
        />

        <MenuItem
          title={t('DRAWER_SETTINGS_ROUTE-WAYPOINT-INSERTION-MODE-SEQUENTIAL-TITLE')}
          size="sm"
          rightDecorator={<Radio value="sequential" size="sm" checked={insertionMode === 'sequential'} />}
          onClick={() => {
            handleInsertionModeChange('sequential')
          }}
        />
      </MenuGroup>

      <MenuGroup>
        <BannerBox>
          <BannerText insertionMode={insertionMode}>
            {insertionMode === 'smart'
              ? t('DRAWER_SETTINGS_ROUTE-WAYPOINT-INSERTION-MODE-DYNAMIC-DESCRIPTION')
              : insertionMode === 'sequential'
                ? t('DRAWER_SETTINGS_ROUTE-WAYPOINT-INSERTION-MODE-SEQUENTIAL-DESCRIPTION')
                : t('DRAWER_SETTINGS_ROUTE-WAYPOINT-INSERTION-MODE-MANUAL-DESCRIPTION')}
          </BannerText>
        </BannerBox>
      </MenuGroup>
    </RouteWaypointInsertionModeContainer>
  )
}

export { RouteWaypointInsertionModeScreen }
