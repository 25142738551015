import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { Waypoint } from '@/domain/models'
import { Coordinates } from '@/domain/protocols/Coordinates'
import { MapPosition } from '@/domain/protocols/MapPosition'
import { SettingsState, SettingsStateProps } from '@/domain/states/SettingsState'
import { GetProximityWaypointsUseCase } from '@/domain/useCases/Map'
import { AddWaypointToRouteUseCase, OverrideWaypointOnRoute } from '@/domain/useCases/Route'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { openDrawer } from '@/presentation/router/DrawerRouter'
import { RouterPaths } from '@/presentation/router/RouterPathsMapper'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { SearchList } from '../../Base/components/SearchList'
import { MapClickEvent } from '../interfaces/MapEvents'
import { MapImperativeInterface } from '../interfaces/MapImperativeInterface'
import { FeatureInfoState, FeatureInfoStateProps } from '../states/FeatureInfoState'
import { MeasuringSegmentState, MeasuringSegmentStateProps } from '../states/MapMeasuringSegmentState'
import { MapSearchState, MapSearchStateMutator, MapSearchStateProps } from '../states/MapSearchState'
import { MapTargetStateActions } from '../states/MapTargetState'
import { ScreenElementsState, ScreenElementsStateProps } from '../states/ScreenElementsState'
import { getFitBoundaries } from '../utils/map'
import { MapSearchContainer, Overlay } from './Map.styles'

function MapSearchPresenter() {
  const resultsRef = useRef<HTMLDivElement>(null)

  const { innerWidth: width, innerHeight: height } = window
  const searchListMaxHeight = 300
  const searchListWidth = 336

  const mapSearchState = useBehaviorSubject<MapSearchStateProps>(MapSearchState)
  const screenElementsState = useBehaviorSubject<ScreenElementsStateProps>(ScreenElementsState)
  const measuringSegmentState = useBehaviorSubject<MeasuringSegmentStateProps>(MeasuringSegmentState)
  const featureInfoState = useBehaviorSubject<FeatureInfoStateProps>(FeatureInfoState)
  const [settingsState, settingsStateMutator] = useGlobalState<SettingsState, SettingsStateProps>(
    InjectionTokens.SettingsState
  )

  const [searchOnMapResultSet, setSearchOnMapResultSet] = useState<Waypoint[]>([])
  const [searchOnMapScreenPos, setSearchOnMapScreenPos] = useState<MapPosition['position']>({ x: 0, y: 0 })

  const searchListPosition = useMemo(() => {
    // tanto largura quanto altura têm um gap de 20px
    const xMargin = 44 - 20
    const yMargin = 20

    const screenWidthLimit = width - searchListWidth - xMargin // largura da toolbar + distância de margem da tela
    const screenHeightLimit = height - searchListMaxHeight - yMargin

    const position = {
      top: searchOnMapScreenPos.y,
      left: searchOnMapScreenPos.x,
      translateX: searchOnMapScreenPos.x > screenWidthLimit && width - xMargin - 100 > searchListWidth ? '-100%' : '0', // 100 is an arbitrary extra margin
      translateY: searchOnMapScreenPos.y > screenHeightLimit ? '-100%' : '0'
    }

    return position
  }, [searchOnMapScreenPos])

  useEffect(() => {
    if (mapSearchState.searchPosition) {
      const mapPosition = mapSearchState.searchPosition
      handleSearchOnMap(mapPosition)
    }
  }, [mapSearchState])

  useEffect(() => {
    const mapClickSubscription = MapClickEvent.subscribe(handleSearchOnMap)

    return () => {
      mapClickSubscription.unsubscribe()
    }
  }, [measuringSegmentState, featureInfoState, screenElementsState])

  const handleSearchOnMap = useCallback(
    (mapPosition: MapPosition) => {
      if (
        measuringSegmentState.active ||
        measuringSegmentState.measuring ||
        featureInfoState.active ||
        screenElementsState.toolPanel
      )
        return

      const resultSet = new GetProximityWaypointsUseCase().execute(mapPosition.coordinates)

      if (resultSet.isSuccess) {
        setSearchOnMapResultSet(resultSet.getValue())
        setSearchOnMapScreenPos(mapPosition.position)
      }
    },
    [setSearchOnMapResultSet, setSearchOnMapScreenPos, measuringSegmentState, featureInfoState, screenElementsState]
  )

  const handleAddWaypointToRoute = useCallback(
    (item: Waypoint): void => {
      if (mapSearchState.searchOverride !== null) {
        new OverrideWaypointOnRoute().execute({ waypoint: item, index: mapSearchState.searchOverride })
      } else {
        const updatedRoute = new AddWaypointToRouteUseCase().execute(item)
        if (updatedRoute.isFailure) return

        sendMetrics('PLANNINGAPP_MAP_POINT_CLICKED')

        const padding: [number, number, number, number] = [
          40 + screenElementsState.boundsDefaultPadding.top,
          75 + screenElementsState.boundsDefaultPadding.right,
          40 + screenElementsState.boundsDefaultPadding.bottom,
          75 + screenElementsState.boundsDefaultPadding.left
        ]

        if (updatedRoute.getValue().waypoints.length === 1) {
          openDrawer(RouterPaths.ROUTES_PANEL)
        } else if (
          updatedRoute.getValue().waypoints.length === 2 &&
          settingsState.waypointInsertionMode !== 'sequential'
        ) {
          const boundaries = getFitBoundaries(updatedRoute.getValue())
          MapImperativeInterface.flyToBounds(boundaries, padding, 500)
        }
      }

      handleCloseSearchOnMap()
    },
    [mapSearchState.searchOverride, screenElementsState.boundsDefaultPadding, settingsState]
  )

  const handleCloseSearchOnMap = useCallback(() => {
    MapSearchStateMutator.setSearchPositionAndOverride(null, null)
    setSearchOnMapResultSet([])
  }, [])

  const handleLocateWaypoint = useCallback((coordinates: Coordinates) => {
    MapTargetStateActions.setTarget(coordinates)
    const padding: [number, number, number, number] = [
      40 + screenElementsState.boundsDefaultPadding.top,
      75 + screenElementsState.boundsDefaultPadding.right,
      40 + screenElementsState.boundsDefaultPadding.bottom,
      75 + screenElementsState.boundsDefaultPadding.left
    ]
    MapImperativeInterface.flyToCoordinates(coordinates, 8, 2000, padding)
  }, [])

  // Listen to ESC key press to close results
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handleCloseSearchOnMap()
      }
    }

    document.addEventListener('keydown', handleKeyDown)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  if (searchOnMapResultSet.length === 0) {
    return null
  }

  return (
    <Overlay onClick={handleCloseSearchOnMap}>
      <MapSearchContainer width={searchListWidth} screenPosition={searchListPosition}>
        <SearchList
          resultsRef={resultsRef}
          waypoints={searchOnMapResultSet}
          maxHeight={searchListMaxHeight}
          onWaypointClick={handleAddWaypointToRoute}
          onLocateWaypointClick={handleLocateWaypoint}
        />
      </MapSearchContainer>
    </Overlay>
  )
}

export { MapSearchPresenter }
