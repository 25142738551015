import { useCallback, useEffect, useState } from 'react'

import GeoJSON from 'geojson'

import { InjectionTokens } from '@/controller/tokens'

import { MapboxCoordinates } from '@/domain/protocols/Coordinates'

import { IMeteorologyRepository, MeteorologyManifest } from '@/data/MeteorologyRepository/IMeteorologyRepository'

import { useInjection } from '@/presentation/contexts/InjectionContext'
import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { createCircleFeature } from '@/presentation/modules/MapScreen/utils/meteorology'

import { MapMeteorologyGeoJsonLayer } from '../components/MapMeteorologyLayer/MapMeteorologyGeoJsonLayer'
import { MapMeteorologyRasterLayer } from '../components/MapMeteorologyLayer/MapMeteorologyRasterLayer'
import { FeatureInfoStateMutator } from '../states/FeatureInfoState'
import { MeteorologyState, MeteorologyStateProps } from '../states/MeteorologyState'

function MapMeteorologyPresenter() {
  const meteorologyRepository = useInjection<IMeteorologyRepository>(InjectionTokens.MeteorologyRepository)

  const meteorologyState = useBehaviorSubject<MeteorologyStateProps>(MeteorologyState)

  const [featureType, setFeatureType] = useState<MeteorologyManifest['type']>(null)
  const [featureStyle, setFeatureStyle] = useState<MeteorologyManifest['featureStyle']>(null)
  const [geoJson, setGeoJson] = useState<GeoJSON.FeatureCollection>({} as GeoJSON.FeatureCollection)
  const [rasterFile, setRasterFile] = useState<string>(null)
  const [rasterCoordinates, setRasterCoordinates] = useState<MapboxCoordinates>(null)

  useEffect(() => {
    const resetState = () => {
      setFeatureType(null)
      setFeatureStyle(null)
      setGeoJson({} as GeoJSON.FeatureCollection)
      setRasterFile(null)
      setRasterCoordinates(null)
    }

    const setNothingToShow = () => {
      resetState()
    }

    const setShowError = () => {
      resetState()
    }

    const setShowGeoJSONSuccess = (
      collection: GeoJSON.FeatureCollection,
      featureStyle: MeteorologyManifest['featureStyle']
    ) => {
      setFeatureType('geojson')
      setFeatureStyle(featureStyle)
      setGeoJson(collection)
      setRasterFile(null)
      setRasterCoordinates(null)
    }

    const setShowRasterSuccess = (rasterFile: string, rasterCoordinates: MapboxCoordinates) => {
      setFeatureType('raster')
      setFeatureStyle(null)
      setGeoJson({} as GeoJSON.FeatureCollection)
      setRasterFile(rasterFile)
      setRasterCoordinates(rasterCoordinates)
    }

    const run = async () => {
      if (!meteorologyState.layer || !meteorologyState.time) {
        setNothingToShow()
        return
      }

      try {
        const packageInfo = await meteorologyRepository.getManifestByLayerName(meteorologyState.layer)

        if (packageInfo.isFailure) {
          setShowError()
          return
        }

        if (packageInfo.getValue().type === 'geojson') {
          const geojsonData = await meteorologyRepository.getGeoJson(meteorologyState.layer, meteorologyState.time)

          if (geojsonData.isFailure) {
            setShowError()
            return
          }

          if (packageInfo.getValue().featureStyle === 'point') {
            const multiPolygonFeatures = geojsonData.getValue().features.map((feature) => {
              return createCircleFeature(feature as GeoJSON.Feature<GeoJSON.MultiPoint>)
            })

            setShowGeoJSONSuccess(
              {
                ...geojsonData.getValue(),
                features: multiPolygonFeatures
              },
              packageInfo.getValue().featureStyle
            )

            return
          }

          setShowGeoJSONSuccess(geojsonData.getValue(), packageInfo.getValue().featureStyle)
          return
        } else if (packageInfo.getValue().type === 'raster') {
          const RasterData = `${import.meta.env.VITE_METEOROLOGY_PACKAGES_API_BASEURL}/${meteorologyState.layer}/${meteorologyState.time}.png`

          const bounds = packageInfo.getValue().boundaries
          const rasterCoordinates: MapboxCoordinates = [
            [bounds[0], bounds[3]],
            [bounds[2], bounds[3]],
            [bounds[2], bounds[1]],
            [bounds[0], bounds[1]]
          ]

          setShowRasterSuccess(RasterData, rasterCoordinates)
          return
        }

        setNothingToShow()
      } catch (error) {
        setShowError()
      }
    }

    run()
  }, [meteorologyState.layer, meteorologyState.time])

  const handleFeaturePress = useCallback(async (features: GeoJSON.Feature[]) => {
    const titles: string[] = []
    const contents: string[] = []

    for (const feature of features) {
      if (feature.properties?.tooltipTitle && !titles.includes(feature.properties.tooltipTitle)) {
        titles.push(feature.properties.tooltipTitle)
      }

      if (feature.properties?.tooltipContent && !contents.includes(feature.properties.tooltipContent)) {
        contents.push(feature.properties.tooltipContent)
      }
    }

    if (titles.length === 0 || contents.length === 0) {
      FeatureInfoStateMutator.hide()
      return
    }
    FeatureInfoStateMutator.setInfo(titles.join(' / '), contents.join('\n\n'))
  }, [])

  if (!meteorologyState.layer) {
    return null
  }

  if (featureType === 'geojson' && featureStyle && featureStyle !== 'raster' && geoJson && geoJson.features) {
    return (
      <>
        {meteorologyState.layer === 'sigmet' && (
          <MapMeteorologyGeoJsonLayer
            id={'sigmet'}
            featureStyle={featureStyle}
            geoJson={geoJson}
            onClick={handleFeaturePress}
          />
        )}
        {meteorologyState.layer === 'stsc' && (
          <MapMeteorologyGeoJsonLayer
            id={'stsc'}
            featureStyle={featureStyle}
            geoJson={geoJson}
            onClick={handleFeaturePress}
          />
        )}
      </>
    )
  }

  if (featureType === 'raster' && rasterFile && rasterCoordinates) {
    return (
      <>
        {meteorologyState.layer === 'satellite-visible' && (
          <MapMeteorologyRasterLayer id={'satellite-visible'} url={rasterFile} coordinates={rasterCoordinates} />
        )}
        {meteorologyState.layer === 'satellite-infrared' && (
          <MapMeteorologyRasterLayer id={'satellite-infrared'} url={rasterFile} coordinates={rasterCoordinates} />
        )}
        {meteorologyState.layer === 'satellite-highlight' && (
          <MapMeteorologyRasterLayer id={'satellite-highlight'} url={rasterFile} coordinates={rasterCoordinates} />
        )}
        {meteorologyState.layer === 'radar-maxcappi' && (
          <MapMeteorologyRasterLayer id={'radar-maxcappi'} url={rasterFile} coordinates={rasterCoordinates} />
        )}
        {meteorologyState.layer === 'sigwx' && (
          <MapMeteorologyRasterLayer id={'sigwx'} url={rasterFile} coordinates={rasterCoordinates} />
        )}
      </>
    )
  }
}

export { MapMeteorologyPresenter }
