import { useCallback, useEffect, useMemo, useState } from 'react'

import { InjectionTokens } from '@/controller/tokens'

import { ADHP, CoordWaypoint } from '@/domain/models'
import { MapPosition } from '@/domain/protocols/MapPosition'
import { MapState, MapStateProps } from '@/domain/states/MapState'
import { RouteState, RouteStateProps } from '@/domain/states/RouteState'
import { DeleteWaypointFromRouteUseCase } from '@/domain/useCases/Route'

import { useBehaviorSubject } from '@/presentation/hooks/useBehaviorSubject'
import { useGlobalState } from '@/presentation/hooks/useGlobalState'
import { useI18n } from '@/presentation/hooks/useI18n'
import { sendMetrics } from '@/presentation/utils/sendMetrics'

import { MapRoute } from '../components/MapRoute'
import { WaypointInfoBox, WaypointPopupInfo } from '../components/MapRoute/WaypointInfo/WaypointInfoBox'
import { RouteWaypointState, RouteWaypointStateProps } from '../states/RouteWaypointState'

function MapRoutePresenter() {
  const { t } = useI18n()

  const [routeState] = useGlobalState<RouteState, RouteStateProps>(InjectionTokens.RouteState)
  const [mapState] = useGlobalState<MapState, MapStateProps>(InjectionTokens.MapState)
  const routeWaypointState = useBehaviorSubject<RouteWaypointStateProps>(RouteWaypointState)
  const [focusedWaypointInfo, setFocusedWaypointInfo] = useState<WaypointPopupInfo | null>(null)
  const [waypointInfoOnMapPos, setWaypointInfoOnMapPos] = useState<MapPosition>({
    position: { x: 0, y: 0 },
    coordinates: null
  })
  const [menuPosition, setMenuPosition] = useState<
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | 'center-left'
    | 'center-right'
    | 'center-bottom'
    | 'center-top'
    | 'default'
  >('top-left')

  const waypoints = useMemo(() => routeState.activeRoute?.waypoints ?? [], [routeState.activeRoute?.waypoints])
  const highlightedWaypoint = useMemo(
    () =>
      routeWaypointState.highlightedWaypoint
        ? waypoints.find((wpt) => routeWaypointState.highlightedWaypoint.toString() === wpt.id.toString())
        : null,
    [routeWaypointState.highlightedWaypoint, waypoints]
  )

  const waypointInfoMenuPosition = useMemo(() => {
    const { innerWidth: width, innerHeight: height } = window

    const margin = 200
    const menuWidth = 300

    const screenRightLimit = width - margin - menuWidth
    const screenLeftLimit = margin
    const screenBottomLimit = height - margin
    const screenTopLimit = margin

    if (waypointInfoOnMapPos.position.y > screenBottomLimit) {
      if (waypointInfoOnMapPos.position.x < screenLeftLimit) {
        setMenuPosition('bottom-left')

        return {
          top: waypointInfoOnMapPos.position.y,
          left: waypointInfoOnMapPos.position.x,
          translateX: '10%',
          translateY: '-110%'
        }
      }

      if (waypointInfoOnMapPos.position.x > screenRightLimit) {
        setMenuPosition('bottom-right')

        return {
          top: waypointInfoOnMapPos.position.y,
          left: waypointInfoOnMapPos.position.x,
          translateX: '-110%',
          translateY: '-110%'
        }
      }

      setMenuPosition('center-bottom')

      return {
        top: waypointInfoOnMapPos.position.y,
        left: waypointInfoOnMapPos.position.x,
        translateX: '-50%',
        translateY: '-110%'
      }
    }

    if (waypointInfoOnMapPos.position.y < screenTopLimit) {
      if (waypointInfoOnMapPos.position.x < screenLeftLimit) {
        setMenuPosition('top-left')

        return {
          top: waypointInfoOnMapPos.position.y,
          left: waypointInfoOnMapPos.position.x,
          translateX: '10%',
          translateY: '10%'
        }
      }

      if (waypointInfoOnMapPos.position.x > screenRightLimit) {
        setMenuPosition('top-right')

        return {
          top: waypointInfoOnMapPos.position.y,
          left: waypointInfoOnMapPos.position.x,
          translateX: '-110%',
          translateY: '10%'
        }
      }

      setMenuPosition('center-top')

      return {
        top: waypointInfoOnMapPos.position.y,
        left: waypointInfoOnMapPos.position.x,
        translateX: '-50%',
        translateY: '10%'
      }
    }

    if (waypointInfoOnMapPos.position.x > screenRightLimit) {
      setMenuPosition('center-right')

      return {
        top: waypointInfoOnMapPos.position.y,
        left: waypointInfoOnMapPos.position.x,
        translateX: '-110%',
        translateY: '-50%'
      }
    }

    if (waypointInfoOnMapPos.position.x < screenLeftLimit) {
      setMenuPosition('center-left')

      return {
        top: waypointInfoOnMapPos.position.y,
        left: waypointInfoOnMapPos.position.x,
        translateX: '10%',
        translateY: '-50%'
      }
    }

    setMenuPosition('default')

    return {
      top: waypointInfoOnMapPos.position.y,
      left: waypointInfoOnMapPos.position.x,
      translateX: '10%',
      translateY: '-50%'
    }
  }, [waypointInfoOnMapPos])

  const handleWaypointClick = useCallback(
    (waypointId: string, _position: MapPosition) => {
      const focusedWaypoint = waypoints.find((wpt) => wpt.id.toString() === waypointId)
      const coordWaypointName = focusedWaypoint.customName
        ? focusedWaypoint.customName
        : t('WAYPOINT_TYPE_COORD-WAYPOINT')
      setFocusedWaypointInfo({
        id: waypointId,
        name: focusedWaypoint instanceof CoordWaypoint ? coordWaypointName : focusedWaypoint.getDisplayName(),
        coordinates: focusedWaypoint.coordinates,
        code: focusedWaypoint instanceof ADHP ? focusedWaypoint.code : undefined,
        adhpType: focusedWaypoint instanceof ADHP ? focusedWaypoint.extra.type : undefined
      })
      setWaypointInfoOnMapPos(_position)
    },
    [waypoints]
  )

  const handleRemoveToRoute = (waypointId: string) => {
    const waypointToRemove = waypoints.find((wpt) => wpt.id.toString() === waypointId)
    new DeleteWaypointFromRouteUseCase().execute(waypointToRemove)

    sendMetrics('PLANNINGAPP_MAP_ADHP_SELECTED', { extra: 'add-to-route' })
  }

  const handleShowInfo = (infoCode: string) => {
    window.open(`https://aisweb.decea.gov.br/?i=aerodromos&codigo=${infoCode}`)
  }

  const handleCloseWaypointInfo = () => {
    setFocusedWaypointInfo(null)
    setWaypointInfoOnMapPos({ position: { x: 0, y: 0 }, coordinates: null })
  }

  if (!routeState.activeRoute) {
    return null
  }

  return (
    <>
      <MapRoute
        waypoints={waypoints}
        highlightedWaypoint={highlightedWaypoint}
        nextWaypointSequence={mapState.nextWaypointSequence}
        onWaypointClick={handleWaypointClick}
      />
      {focusedWaypointInfo && waypointInfoOnMapPos.position.x !== 0 && waypointInfoOnMapPos.position.y !== 0 && (
        <WaypointInfoBox
          pointerPosition={menuPosition}
          focusedWaypoint={focusedWaypointInfo}
          menuScreenPos={waypointInfoMenuPosition}
          onOverlayClick={handleCloseWaypointInfo}
          onRemoveWaypointClick={handleRemoveToRoute}
          onShowInfoClick={handleShowInfo}
        />
      )}
    </>
  )
}

export { MapRoutePresenter }
