import { BehaviorSubject } from 'rxjs'

import { Waypoint } from '@/domain/models'

export interface RouteWaypointStateProps {
  highlightedWaypoint: Waypoint['id']
}

const INITIAL_STATE: RouteWaypointStateProps = {
  highlightedWaypoint: null
}

export const RouteWaypointState = new BehaviorSubject<RouteWaypointStateProps>(INITIAL_STATE)

export const RouteWaypointStateMutator = {
  setHighlightedWaypoint: (waypointId: Waypoint['id']) => {
    RouteWaypointState.next({
      ...RouteWaypointState.getValue(),
      highlightedWaypoint: waypointId
    })
  },

  clear: () => {
    RouteWaypointState.next({
      highlightedWaypoint: null
    })
  }
}
