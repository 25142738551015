import { RouteWaypointInsertionMode } from '../protocols/RouteWaypointInsertionMode'
import { SerializableState } from './SerializableState'

export interface SettingsStateProps {
  waypointInsertionMode: RouteWaypointInsertionMode
}

const INITIAL_STATE: SettingsStateProps = {
  waypointInsertionMode: 'smart'
}

export class SettingsState extends SerializableState<SettingsStateProps> {
  constructor() {
    super(INITIAL_STATE)
  }

  getSetting<K extends keyof SettingsStateProps>(key: K): SettingsStateProps[K] {
    const snapshot = this.getStateSnapshot()
    return snapshot[key]
  }

  setWaypointInsertionMode(waypointInsertionMode: RouteWaypointInsertionMode): void {
    this._updateStateProp('waypointInsertionMode', waypointInsertionMode)
  }

  getKey(): string {
    return 'settings'
  }
}
