import styled from 'styled-components'

import { appZIndex } from '@/presentation/utils/zIndexMapper'

export const Wrapper = styled.div`
  position: absolute;
  bottom: 2.5%;
  left: calc(16px + 48px + 12px);
  max-width: 68%;
  width: fit-content;

  background-color: transparent;
  z-index: ${appZIndex.BANNER_FOOTER};
`

export const BannerFooterContainer = styled.div`
  max-width: 736px;
  min-width: 475px;
  width: 100%;
  height: 64px;
  border-radius: 10px;
  padding: 0 16px;
  background-color: ${({ theme }) => theme.colors.neutralL5};

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`

export const CloseButton = styled.button`
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.neutralL4};
  }
`

export const AppRating = styled.div`
  display: flex;
  gap: 8px;

  & > svg {
    min-height: 32px;
    min-width: 32px;
  }
`

export const RatingContent = styled.div``

export const RatingTitleWrapper = styled.div`
  display: flex;
  gap: 6px;
`

export const RatingTitle = styled.span`
  ${({ theme }) => theme.fonts.infoSM}
  color: ${({ theme }) => theme.colors.secondary};
`

export const RatingDescription = styled.p`
  ${({ theme }) => theme.fonts.infoXS}
  font-weight: 600;
  color: ${({ theme }) => theme.colors.secondaryL3};
`

export const ModalButton = styled.button`
  background-color: ${({ theme }) => theme.colors.neutralL4};
  border: none;
  cursor: pointer;
  border-radius: 10px;
  height: 32px;
  padding: 5px 8px;
  display: flex;
  align-items: center;
  ${({ theme }) => theme.fonts.titleXS}
  color: ${({ theme }) => theme.colors.blueL1};

  & > svg {
    min-height: 24px;
    min-width: 24px;
  }
`

export const StoresButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 11px;
`

export const StoreLinkButton = styled.a``
