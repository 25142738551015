import { useMemo, useState } from 'react'

import CloseIcon from '@presentation/assets/procedureCharts/close.png'
import OpacityActiveIcon from '@presentation/assets/procedureCharts/opacity-active.png'
import OpacityIcon from '@presentation/assets/procedureCharts/opacity.png'
import { Position } from 'geojson'

import { InjectionTokens } from '@/controller/tokens'

import { MapboxCoordinates } from '@/domain/protocols/Coordinates'
import { ProceduresState } from '@/domain/states/ProceduresState'

import { useInjection } from '@/presentation/contexts/InjectionContext'

import { stackIds } from '../../containers/MapStackPresenter'
import { MapRasterLayer } from '../MapView/MapRasterLayer'
import { MapRasterSource } from '../MapView/MapRasterSource'
import { MapSymbolLayer } from '../MapView/MapSymbolLayer'
import { MapSymbolSource } from '../MapView/MapSymbolSource'

interface MapProcedureProps {
  url: string
  boundaries: [Position, Position, Position, Position]
}

function MapProcedure(props: MapProcedureProps) {
  const { url, boundaries } = props

  const proceduresState = useInjection<ProceduresState>(InjectionTokens.ProceduresState)

  const [opacityActive, setOpacityActive] = useState<boolean>(false)

  const formattedBoundaries = useMemo(() => {
    return boundaries.map((position) => {
      return [position[1], position[0]]
    }) as MapboxCoordinates
  }, [boundaries])

  const closeButtonCoords = useMemo(() => {
    return formattedBoundaries[1]
  }, [formattedBoundaries])

  const opacityButtonCoords = useMemo(() => {
    return formattedBoundaries[0]
  }, [formattedBoundaries])

  const ids = {
    source: {
      closeButton: 'map-procedure-chart-close-button-source',
      opacityButton: 'map-procedure-chart-opacity-button-source',
      chart: 'map-procedure-chart-source'
    },
    layer: {
      closeButton: 'map-procedure-chart-close-button-layer',
      opacityButton: 'map-procedure-chart-opacity-button-layer',
      chart: 'map-procedure-chart-layer'
    }
  }

  return (
    <>
      <MapSymbolSource
        id={ids.source.opacityButton}
        data={{
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: opacityButtonCoords,
            type: 'Point'
          }
        }}
      >
        <MapSymbolLayer
          id={ids.layer.opacityButton}
          sourceId={ids.source.opacityButton}
          imageSrc={opacityActive ? OpacityActiveIcon : OpacityIcon}
          icon={opacityActive ? 'opacity-active' : 'opacity'}
          layout={{
            'icon-size': 0.25
          }}
          minZoomLevel={6}
          onSymbolClick={() => {
            setOpacityActive(!opacityActive)
          }}
          beforeLayerId={stackIds.procedureChartButtons}
        />
      </MapSymbolSource>
      <MapSymbolSource
        id={ids.source.closeButton}
        data={{
          type: 'Feature',
          properties: {},
          geometry: {
            coordinates: closeButtonCoords,
            type: 'Point'
          }
        }}
      >
        <MapSymbolLayer
          id={ids.layer.closeButton}
          sourceId={ids.source.closeButton}
          imageSrc={CloseIcon}
          icon={'close'}
          layout={{
            'icon-size': 0.25
          }}
          minZoomLevel={6}
          onSymbolClick={() => {
            proceduresState.setPresentedProcedure(null)
          }}
          beforeLayerId={stackIds.procedureChartButtons}
        />
      </MapSymbolSource>
      <MapRasterSource id={ids.source.chart} url={url} coordinates={formattedBoundaries}>
        <MapRasterLayer
          id={ids.layer.chart}
          sourceId={ids.source.chart}
          opacity={opacityActive ? 0.8 : 1}
          beforeLayerId={stackIds.procedureChart}
        />
      </MapRasterSource>
    </>
  )
}

export { MapProcedure }
