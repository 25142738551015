import styled from 'styled-components'
export const RouteWaypointInsertionModeContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const Title = styled.h1`
  ${({ theme }) => theme.fonts.labelSM}
  padding-bottom: 12px;
  padding-right: 12px;
  width: 100%;
`

interface BannerProps {
  insertionMode: string
}

export const BannerBox = styled.div`
  background-color: ${({ theme }) => theme.colors.secondaryD2 ?? '#0d1012'};
  align-items: center;
  flex-direction: row;
  margin-top: 12px;
  justify-content: flex-start;
  border-radius: 10px;
  padding: 16px;
`

export const BannerText = styled.div<BannerProps>`
  ${({ theme }) => theme?.fonts?.infoMD ?? {}}
  color: ${({ theme }) => theme.colors.secondaryL3 ?? '#647f8c'};
`
