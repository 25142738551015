enum RouterPaths {
  MAP = '/map',
  ROUTES_PANEL = '/routes-panel',
  SAVED_ROUTES = '/saved-routes',
  PROCEDURE_CHARTS = '/procedure-charts',
  MARKET = '/market',
  BETA = '/beta-info',
  MOBILE = '/mobile',
  NEWS = '/news',
  SETTINGS = '/settings',
  SETTINGS__USER_WAYPOINTS = 'user-waypoints',
  SETTINGS__USER_WAYPOINTS__CREATE = 'create',
  SETTINGS__USER_WAYPOINTS__EDIT = 'edit/:name/:id',
  SETTINGS__DATA_AND_CHARTS = 'data-and-charts',
  SETTINGS__ROUTE_WAYPOINT_INSERTION_MODE = 'route-waypoint-insertion-mode'
}

export { RouterPaths }
