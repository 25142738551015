import React, { ReactNode, useLayoutEffect } from 'react'

import { Crisp } from 'crisp-sdk-web'

import { InjectionTokens } from '@/controller/tokens'

import { AuthState } from '@/domain/states/AuthState'

import { useInjection } from '@/presentation/contexts/InjectionContext'

function ChatPresenter(): ReactNode {
  const authState = useInjection<AuthState>(InjectionTokens.AuthState)

  useLayoutEffect(() => {
    const auth = authState.getStateSnapshot()

    Crisp.configure(import.meta.env.VITE_CRISP_WEBSITE_ID, {
      autoload: false
    })

    Crisp.user.setEmail(auth.user.email)
    Crisp.user.setNickname(`${auth.user?.profile?.name} ${auth.user?.profile?.surname}`)

    Crisp.session.setData({
      pilot: auth.user?.profile?.pilot ? 'Piloto' : 'Não piloto',
      aviation_segment: auth.user?.profile?.aviationSegment,
      aircraft_type: auth.user?.profile?.aircraftType,
      self_classification: auth.user?.profile?.selfClassification,
      creation_date: auth.user.creationDate,
      email_confirmation_url: `https://app.nexatlas.com/auth/confirmEmail/${auth.user.emailConfirmationToken}`,
      confirmed_email: auth.user.confirmedEmail,
      trial_to: auth.user.trialTo,
      plan_id: auth.user.planId,
      plan_name: auth.user.plan && auth.user.plan.text,
      subscription_status: auth.user.subscriptionStatus,
      subscription_method: auth.user.subscriptionMethod,
      subscription_created: auth.user.subscriptionCreated,
      subscription_updated: auth.user.subscriptionUpdated,
      subscription_boleto_url: auth.user.subscriptionBoletoUrl
    })

    Crisp.setTokenId(auth.user.salt)

    Crisp.load()

    if (auth.user.subscriptionStatus === 'paid') {
      Crisp.session.pushEvent('enter-paid')
    }

    if (auth.user.subscriptionMethod === 'boleto') {
      Crisp.session.pushEvent('enter-boleto')
    }

    if (
      auth.user.subscriptionMethod === 'credit_card' &&
      (auth.user.subscriptionStatus === 'pending_payment' || auth.user.subscriptionStatus === 'unpaid')
    ) {
      Crisp.session.pushEvent('enter-credit-card-unpaid')
    }
  }, [])

  return null
}

export { ChatPresenter }
