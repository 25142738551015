import { AirspaceType } from '@/domain/protocols/Airspaces'

import SymbolAdiz from '@/presentation/assets/airspaceSymbols/symbol-adiz.png'
import SymbolAfis from '@/presentation/assets/airspaceSymbols/symbol-afis.png'
import SymbolAtz from '@/presentation/assets/airspaceSymbols/symbol-atz.png'
import SymbolCtr from '@/presentation/assets/airspaceSymbols/symbol-ctr.png'
import SymbolDangerous from '@/presentation/assets/airspaceSymbols/symbol-dangerous.png'
import SymbolFca from '@/presentation/assets/airspaceSymbols/symbol-fca.png'
import SymbolFir from '@/presentation/assets/airspaceSymbols/symbol-fir.png'
import SymbolFiz from '@/presentation/assets/airspaceSymbols/symbol-fiz.png'
import SymbolProhibited from '@/presentation/assets/airspaceSymbols/symbol-prohibited.png'
import SymbolRestricted from '@/presentation/assets/airspaceSymbols/symbol-restricted.png'
import SymbolTma from '@/presentation/assets/airspaceSymbols/symbol-tma.png'

export const airspaceColor: Record<AirspaceType, string> = {
  adiz: '#6B7E33',
  afis: '#720EA0',
  atz: '#34ADFF',
  ctr: '#EB01EB',
  fir: '#08090B',
  firSector: '#08090B',
  fiz: '#663E0E',
  fca: '#25520C',
  suaDangerous: '#FFA903',
  suaProhibited: '#FA1A1A',
  suaRestricted: '#A001AE',
  tma: '#1239DF',
  tmaSector: '#1239DF'
}

export const airspaceTextColor: Record<AirspaceType, string> = {
  adiz: '#FFFFFF',
  afis: '#FFFFFF',
  atz: '#000000',
  ctr: '#000000',
  fir: '#FFFFFF',
  firSector: '#FFFFFF',
  fiz: '#FFFFFF',
  fca: '#FFFFFF',
  suaDangerous: '#000000',
  suaProhibited: '#000000',
  suaRestricted: '#FFFFFF',
  tma: '#FFFFFF',
  tmaSector: '#FFFFFF'
}

export const airspaceImages: Record<AirspaceType, string> = {
  adiz: SymbolAdiz,
  afis: SymbolAfis,
  atz: SymbolAtz,
  ctr: SymbolCtr,
  fir: SymbolFir,
  firSector: SymbolFir,
  fiz: SymbolFiz,
  fca: SymbolFca,
  suaDangerous: SymbolDangerous,
  suaProhibited: SymbolProhibited,
  suaRestricted: SymbolRestricted,
  tma: SymbolTma,
  tmaSector: SymbolTma
}

export const airspaceLabelMinLevel: Record<AirspaceType, number> = {
  adiz: 22,
  afis: 6,
  atz: 8,
  ctr: 6,
  fir: 0,
  firSector: 6,
  fiz: 6,
  fca: 6,
  suaDangerous: 8,
  suaProhibited: 9,
  suaRestricted: 7.5,
  tma: 6,
  tmaSector: 6
}

export const airspaceLabelSeparationDecimals: Record<AirspaceType, number> = {
  adiz: 1,
  afis: 1,
  atz: 1,
  ctr: 1,
  fir: 1,
  firSector: 1,
  fiz: 1,
  fca: 1,
  suaDangerous: 2,
  suaProhibited: 2,
  suaRestricted: 2,
  tma: 1,
  tmaSector: 1
}
